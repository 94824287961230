.App {
  font-family: "Baumans", cursive;
  text-align: center;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  height: 100vh;
  width: 100vw;
}

body {
  background-color: #1c0a60;
  color: #fff;
  text-align: center;
  font-family: "Baumans", cursive;
}

.App-header {
  font-family: "Baumans", cursive;
  font-size: calc(10px + 15vmin);
  color: #20e9e7;
  line-height: 0em;
}

input {
  color: #e96020;
  border-radius: 0px;
  background: #1c0a60;
  /* box-shadow: inset 4px 4px 16px #051315, inset -4px -4px 16px #092125; */
  border: 0px;
  border-bottom: 2px solid rgba(34, 166, 191, 0.2);
  width: 12em;
  height: 3em;
  font-size: 1em;
  padding-left: 1em;
  margin: 5px;
  font-weight: lighter;
}
.test {
  border: 2px dashed red;
}
textarea:focus,
input:focus {
  outline: none;
}
*:focus {
  outline: none;
}
button {
  font-size: 1.3em;
  color: rgba(255, 255, 255, 0.6);
  background: rgba(255, 255, 255, 0);
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  margin-top: 0.5em;
  padding: 0.4em 0.8em;
}

.What-is {
  font-family: "Dosis", sans-serif;
  padding: 5vw;
  font-size: calc(10px + 2vmin);
  color: #20e9e7;
  text-align: center;
}

.Subtitle {
  font-size: calc(10px + 1.6vmin);
  margin-top: 0;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.3);
  width: 100vw;
  text-align: center;
}

.App-link {
  color: #61dafb;
}

p.style-one {
  border: 0;
  margin-left: 40%;
  margin-top: 0;
  margin-bottom: 0;
  width: 20%;
  height: 1px;
  background: #333;
  background-image: -webkit-linear-gradient(left, #ccc, #333, #ccc);
  background-image: -moz-linear-gradient(left, #ccc, #333, #ccc);
  background-image: -ms-linear-gradient(left, #ccc, #333, #ccc);
  background-image: -o-linear-gradient(left, #ccc, #333, #ccc);
}
